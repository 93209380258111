@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;1,700&display=swap');
@import '../styles/variables';
@import '../styles/flex';
@import '../styles/navbar';
@import '../styles/button';
@import '../styles/footer';
@import '../styles/profile';
@import '../styles/article';
@import '../styles/all-article';
@import '../styles/information';
@import '../styles/modal';
@import '../styles/max-width';
@import '../styles/min-width';
@import '../styles/splide';


.img__video-cover {
  width: 100%;
}

.card {
  position: relative;
  width: 400px;
  height: 240px;
  overflow: hidden;
  padding: 20px !important;
  border: 0;
  border-radius: 0;
}

.card>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px !important;
}

.card__image {
  display: block;
  padding: 20px !important;
}

.card__info {
  @include center-column();
  opacity: 0;
  font-size: 20px;
  color: white;
  background-color: $color-black80;
  transition: opacity 300ms ease;
  padding: 20px !important;
  margin: 20px;

  hr {
    width: 40%;
    border: 1px solid $color-secondary;
  }

  h4 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.card:hover .card__info,
.card:focus-within .card__info {
  opacity: 1;
}

.card__info a {
  color: #fdca40;
}

.reward {
  @include center-row();
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
  color: $color-white;

  .reward-item {
    padding: 0 20px !important;
  }

  .reward-total {
    font-size: 64px;
  }

  .reward-name {
    font-size: 18px;
  }

  .reward-desc {
    font-size: 13px;
  }
}

.article {
  .title-text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 4px;
    color: $color-black;
    text-decoration: none;
  }

  p {
    color: $color-black50;
    font-size: 14px;
  }

  img {
    width: 220px;
  }

  hr{
    margin-top: 24px;
    width: 67%;
    border: 0.5px solid $color-secondary;
    border-radius: 1px;
  }
}

.ppdb {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .ppdb-desc {
    @include center-column();
    padding: 0 140px;
    background-color: $color-overlay;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    color: $color-white;
    line-height: 2em;
    font-size: 18px;
    overflow: hidden;
  }

  .ppdb-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.maps {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.association {
  margin-top: 10px;
}

.text-secondary {
  color: $color-secondary !important;
}

.wrapper {
  padding: 20px;
  max-width: 1200px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.right {
  float: right !important;
}

.parent {
  @include center-column();
  width: 45%;
  padding: 20px;
  height: 240px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;

  .subtitle{
    @include center-column();
    width: 80%;
    height: 40px;
    background-color: $color-secondary;
    color: $color-white;
    display: none;
    z-index: 1;
    position: absolute;
    bottom: -10px;
    left: 36px;
    padding: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }
}

.child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;

  img {
    width: 100%;
  }

  span {
    font-size: 14px;
    display: none;
    color: #ffffff !important;
    font-family: sans-serif;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 60px;
    cursor: pointer;
    text-decoration: none;
  }
}

.parent:hover .child,
.parent:focus .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.parent:hover .child:before,
.parent:focus .child:before {
  display: block;
}

.parent:hover span,
.parent:focus span {
  display: flex;
  @include center-column();
}

.child:before {
  content: "";
  display: none;
  height: 120%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-overlay;
}

/* Media Queries */
@media screen and (max-width: 960px) {
  .container-fluid {
    padding: 0 20px;
  }

  .parent {
    width: 100%;
    margin: 0px
  }

  .wrapper {
    padding: 0;
  }
}
.hello {
  display: none
}

footer {
  .container {
    @include center-row();
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: #fff !important;
}

#map {
  height: 100%;
  width: 100%;
}

.overview-tab{
  #tugas-pokok{
    p{
      margin-left: -24px;
    }
  }
}

.login-logo{
  width: 180px;
  height: auto;
}

.img-social{
  transition: 0.3s;

  &:hover{
    transition: 0.3s;
    transform: scale(1.1);
  }
}

.search-list::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.search-list::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

.search-list::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $color-primary;
}


.search-list{
  position: absolute;
  top: 102px;
  z-index: 1;
  width: 100%;
  max-height: 50vh;
  background-color: $color-white;
  box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  overflow-x: hidden;

  ul{
    list-style: none;
    padding: 0;
    margin: 0;

    a{
      &:hover{
        text-decoration: none;
      }
    }

    li{
      padding: 24px 120px;
      transition: 0.3s;
      color: $color-black;
      &:hover{
        background-color: $color-white-hover-soft;
        transition: 0.3s;
      }
    }
  }
}