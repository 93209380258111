// @media (max-width: 576px) {
//     .ppdb-desc{
//         padding: 0 40px;
//     }
// }

// @include media-breakpoint-down(sm) {
//     .ppdb-desc{
//         padding: 0 40px !important;
//     }
// }

@media (max-width: 767.98px) {
    #hero {
        overflow-y: hidden !important;
    }

    #map {
        height: 500px !important;
    }

    .dropdown-menu {
        border: none;

        .dropdown-item {
            padding: 12px 24px;
        }
    }

    .vimis {
        img {
            width: 90% !important;
        }
    }

    .profile-content {
        .left {
            .nav {
                @include row-start();
                flex-direction: row !important;
                white-space: nowrap;
                overflow-x: auto;
                margin-left: 16px;

                .active {
                    border-radius: 24px !important;
                    padding: 12px 16px !important;
                    color: $color-primary !important;
                }

                .nav-link {
                    float: none;
                    color: $color-black !important;
                }
            }
        }

        .overview-tab {
            .nav-link {
                padding: 12px 16px !important;
            }
        }
    }

    .nav-item {
        padding: 16px 0 !important;
    }

    .searchbar {
        top: 480px !important;
    }

    .navbar-big {
        display: none !important;
    }

    .navbar-mobile {
        display: block !important;
        z-index: 99;
    }

    .ppdb {
        width: auto !important;
        height: 560px !important;
        margin: 0;
        padding: 0;

        .ppdb-desc {
            padding: 0 50px !important;
        }

        .ppdb-img {
            width: 100% !important;
            min-height: 560px !important;
            object-fit: cover;
        }
    }

    .maps {
        height: 560px !important;
    }

    .img__video-cover {
        height: 100% !important;
        width: auto !important;
    }

    .parent {
        padding-bottom: 50px;
        .subtitle {
            display: block;
        }

        .child{
            span{
                margin-top: 150px !important;
            }
        }
    }

    .article-content{
        margin-top: 0px !important;
    }

    footer {
        .row {
            .col-12 {
                @include column-start();
                ul {
                            text-align: center;
                }
            }
            p {
                text-align: center;
            }
        }

        .container {
            @include center-column();
        }
    }

    .overview-tab{
      #tugas-pokok{
        p{
          margin-left: 0;
        }
      }
    }

    .tab-pane{
        .row-start{
            color: $color-black50;
        }
    }


    .article {
        hr{
        width: 50% !important;
        }
    }

    .article__row{
        display: flex;
        flex-direction: column-reverse;
    }

    .article-navigation{
        box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);
    }

}
