.navbar{
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-mobile {
    display: none !important;
    @include center-row();
    transition: background-color 0.3s ease-in-out !important;
    background: rgb(255, 255, 255) !important;
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 16px 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

    .navbar-brand{
        margin: 0;
    }

    .brand-text {
        color: $color-black;

        .title-kementerian {
            font-size: 0.5em;
            margin: 0;
        }

        .title-smti {
            font-size: 0.8em;
            font-weight: 500;
            margin: 0;
            margin-top: 4px;
        }
    }

    &:hover {
        transition: background-image 0.3s ease-in-out !important;
        background: $color-white !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        transition: 0.3s;

        .nav-item {
            .nav-link {
                color: $color-black;
            }

            .prodi-container {
                @include center-row();
                padding-right: 270px;
            }

            .information-container {
                @include center-row();
                padding-right: 270px;
            }
        }
    }

    .nav-item {
        padding: 23px 0;

        .btn-secondary {
            color: $color-white !important;
        }

        .nav-link {
            color: $color-black;
            margin-left: 24px;
            transition: 0.3s;

            &:hover {
                color: $color-white-hover;
                transition: 0.3s;

                .jurusan {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }

                .more-info {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }
            }
        }
    }

    #more-info {
        &:hover {
            i {
                transform: rotate(180deg);
                transition: 0.5s;
            }
        }
    }

    .nav-link__dropdown {
        // opacity: 0 !important;
        visibility: hidden !important;
    }

    .nav-item-dropdown {
        &:hover {
            .nav-link__dropdown {
                // opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }
}

.navbar-big {
    display: block !important;
    @include center-row();
    transition: background-color 0.3s ease-in-out !important;
    background: rgb(255, 255, 255) !important;
    position: absolute;
    z-index: 1;
    width: 100%;

    &:hover {
        transition: background-image 0.3s ease-in-out !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        transition: 0.3s;

        .nav-item {
            .nav-link {
                color: $color-white;
            }

            .prodi-container {
                @include center-row();
                padding-right: 270px;
            }

            .information-container {
                @include center-row();
                padding-right: 270px;
            }
        }

        .brand-text {
            color: $color-white;
            transition: 0.3s;
        }
    }

    .nav-item {
        padding: 28px 0;

        .btn-secondary {
            color: $color-white !important;
        }

        .nav-link {
            color: $color-black;
            margin-left: 24px;
            transition: 0.3s;

            &:hover {
                color: $color-white-hover;
                transition: 0.3s;

                .jurusan {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }

                .more-info {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }
            }
        }
    }

    #more-info {
        &:hover {
            i {
                transform: rotate(180deg);
                transition: 0.5s;
            }
        }
    }

    .nav-link__dropdown {
        // opacity: 0 !important;
        visibility: hidden !important;
    }

    .nav-item-dropdown {
        &:hover {
            .nav-link__dropdown {
                // opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }
}

.register {
    position: relative;
    display: inline-block;
    color: royalblue;
    overflow: hidden;
    background: linear-gradient(to right, $color-secondary, $color-secondary-hover 50%, $color-white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
    text-decoration: none;

    &:hover {
        background-position: 0 100%;
    }

    hr {
        border: 1px solid $color-secondary;
        border-radius: 1px !important;
        margin-top: 4px !important;

    }
}

.another-info-list{
    ul {
        max-height: 80px;

        li {
            font-size: 14px;
            margin-bottom: 8px;
            list-style-type: disc !important;

            a {
                color: $color-secondary;
            }
        }
    }
}

.program-checklist {
    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 120px;

        li {
            font-size: 14px;
            margin-right: 80px;
            margin-bottom: 8px;
            list-style-type: disc !important;

            a {
                color: $color-secondary;
            }
        }
    }
}

.nav-link__dropdown-item {
    // opacity: 0.0;
    // display: none;
    visibility: hidden;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: $color-primary-dark90;
    width: 100%;
    height: 240px;
    color: $color-white;

    &:hover {
        .nav-link__dropdown {
            display: block !important;
        }
    }

    ul {
        list-style-type: circle;
    }
}

.nav-link__dropdown {
    display: flex;
    justify-content: flex-end;
    background-color: $color-primary-dark;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;

    &:hover {
        transition: 0.5s;
        border-bottom: 1px solid $color-white !important;

        .active-hover {
            .nav-link__dropdown-item {
                display: block !important;
            }
        }
    }

    .prodi {
        padding: 20px 30px;
    }

    .information {
        padding: 20px 30px;
    }

    ul {
        li {
            list-style: none;
            color: $color-white;
            font-size: 14px;
        }
    }

    .active-hover {
        &:hover {
            background-color: $color-primary-dark110;
            color: $color-secondary;
            cursor: pointer;

            .nav-link__dropdown-item {
                // opacity: 1.0 !important;
                visibility: visible !important;
            }
        }
    }
}

.searchbar {
    z-index: 1;
    position: absolute;
    min-width: 50px;
    width: 0%;
    height: 50px;
    float: right;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;

    &::after {
        height: 300px !important;
    }
}

.searchbar-input {
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    background: #6200ea00;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0px 55px 0px 20px;
    font-size: 20px;
    color: #fff
}

.searchbar-input::-webkit-input-placeholder {
    color: #fff
}

.searchbar-input:-moz-placeholder {
    color: #fff
}

.searchbar-input::-moz-placeholder {
    color: #fff
}

.searchbar-input:-ms-input-placeholder {
    color: #fff
}

.searchbar-icon,
.searchbar-submit {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    top: 0;
    font-family: verdana;
    font-size: 22px;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background: #6200ea00;
}

.searchbar-open {
    background-color: $color-primary-dark;
    width: 100%;
    height: 102px;
    padding-top: 20px;
    padding-left: 100px;
    z-index: 1;
    right: 0;
    top: 0;

    .searchbar-icon {
        top: 20px;
        right: 100px;
    }
}


.brand-text {
    color: $color-black;

    .title-kementerian {
        font-size: 0.6em;
        margin: 0;
    }

    .title-smti {
        font-size: 0.9em;
        font-weight: 500;
        margin: 0;
        margin-top: 4px;
    }
}

.blue-nav {

    .nav-item {
        .nav-link {
            color: $color-white !important;
        }
    }

    .brand-text {
        color: $color-white !important;
    }
}

.search-list{
    display: none;
}

.show-search{
    display: block;
}

.hide-search{
    display: none;
}