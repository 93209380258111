.article-content {
    position: relative;
    left: 0;
    right: 0;
    margin-top: -100px;
    z-index: 1;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);
    background-color: $color-white;
}

.article-page{
    img{
        max-height: 800px;
        object-fit: cover;
    }
}

.writer {
    color: $color-black50;
}

.folder {
    color: $color-blue;
}

.relate-article {
    .relate-article__item {
        padding: 20px;

        a {
            color: $color-black;
        }
    }

    .relate-article__detail {
        color: $color-black5;
        font-size: 10px;
    }
}
