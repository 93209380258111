.information-wrapper {
    padding: 60px 0;
}

.information-content{
    z-index: 1;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.05);
    background-color: $color-white;
}

.categories-wrapper {
    @include center-column();
    width: 100%;
    height: auto;
    border: 1px solid $color-black5;

    .categories-item{
        width: 100%;
        min-height: 40px;;
        background-color: $color-black5;
        margin-bottom: 16px;
        transition: 0.3s;

        &:hover{
            transform: scale(0.94);
            transition: 0.3s;
        }
    }
}

.information-detail__last-update{
    color: $color-black50;
    font-size: 14px;
    margin-top: 0;
    padding-top: 0;
}

.color-white{
    color: $color-white;
}

.information__part{
    background-color: $color-primary10;
    padding: 8px 16px;
}

.information__last-update{
    color: $color-black50;
}



.readmore {
    border-radius: 4px;
    border: none;
    color: $color-primary;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
 }

 .readmore {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
 }

 .readmore:before {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0px;
    right: 0px;
    transition: 0.5s;
 }

 .readmore:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0px;
    right: 0px;
    transition: 0.5s;
 }

 .readmore:hover {
    padding-right: 24px;
    padding-left: 8px;

 }

 .readmore:hover:after {
    opacity: 1;
    right: 10px;
 }


 Pug SCSSResult
EDIT ON
/* The magic */
.col {
  // Prepare for absolute slide
  overflow: hidden;
  position: relative;
}

.slide {
  // Position inside column
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  
  /* 
    Visibility delay gives the previously hovered element time to slide out before disappearing.
    Remove the `visibility` transition to slide in current element without sliding out previous element
  */
  $speed: 0.275s;
  transition: all $speed ease-in-out, visibility 0s $speed;
  visibility: hidden;
  will-change: transform;
  // Slides start below their columns, giving upward motion on hover
  transform: translateY(100%);
}

.row:hover {
  // Next row, slides are above their columns, giving downward motion on hover
  & ~ .row .slide { transform: translateY(-100%); }
  
  // Current row, slides to the right of their columns, giving left motion on hover
  .slide { transform: translateX(100%); }
  
  // Current row, next slides, slides to the left of their columns, giving right motion on hover
  & .col:hover ~ .col .slide { transform: translateX(-100%); }
  
  // Current slide
  .col:hover .slide {
    transform: none;
    visibility: visible;
    transition-delay: 0s;
  }
}

/* Pen styling */
// This a mess, ignore

* { box-sizing: border-box; }

body {
  background: #fefefe;  
  color: #333;
  font: 14px /1.5 "Fira Sans", sans-serif;
}

h1 {
  font-size: 2.5rem;
  font-weight: 300;
  margin: 1.5em 0.5rem 1em;
  text-align: center;
}