.modal-content{
    border: none;
    border-radius: 0;
}

.close{
    @include center-row();
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $color-white-hover-soft;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 0;
}