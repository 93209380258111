.all-article-wrapper{
    min-height: 100vh;
}

.article-navigation {
    ul {
        @include row-start();
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 8px 16px;
            color: $color-black50;
            margin-left: 12px;
            margin-bottom: 12px;

            &:hover{
                background-color: $color-white-hover-soft;
                cursor: pointer;
                transition: 0.3s;
            }
        }

        .active{
            color: $color-secondary;
            background-color: $color-secondary20;
        }

    }
}

.article-badge{
    background-color: $color-secondary20;
    color: $color-secondary;
    padding: 4px 16px;
    transition: 0.3s;
    font-size: 14px;

    &:hover{
        transform: scale(0.96);
        transition: 0.3s;
        text-decoration: none;
        color: $color-secondary;
    }
}

.article-badge-day{
    color: $color-black50;
    font-size: 14px;
}

// .scrollable-article{
//     max-height: 100vh;
//     overflow-y: scroll;
// }
.article{
    div::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        background-color: $color-secondary30;
        border: 1.5px solid $color-white;
        border-radius: 5px;
      }
      
    div::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $color-secondary;
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
}

.article-img {
    height: 140px;
    object-fit: cover;
}

.article-thumbnail-img {
    height: 100px;
    object-fit: cover;
}